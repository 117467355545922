<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <template v-if="breadcrumbs && breadcrumbs.length > 0">
          <el-breadcrumb-item v-for="(item, index) in breadcrumbs" :key="index" :to="item.to">{{ item.label }}</el-breadcrumb-item>
        </template>
        <el-breadcrumb-item>评测情况</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-container">
      <div class="yt-content">
        <div class="block info">
          <el-avatar :size="72" :src="examInfoList.avatar" style="margin-right: 30px"></el-avatar>
          <div>
            <p>{{ examInfoList.userName }}</p>
            <div style="display: flex; align-items: center">
              <YTIcon :href="'#icon-gangwei'" style="fill: #46B2A7" />
              <span>{{ examInfoList.postName }}</span>
              <YTIcon :href="'#icon-shijian'" style="fill: #7CAEEF" />
              <span>考试时间：{{ $formatTime(examInfoList.startTime, 'yyyy-MM-dd') }}</span>
              <YTIcon :href="'#icon-time'" style="fill: #FFBB00" />
              <span>用时：{{ examInfoList.duration }}分钟</span>
              <YTIcon :href="'#icon-pingjunfenshu'" style="fill: #FF5050" />
              <span>得分：{{ examInfoList.score }}分</span>
            </div>
          </div>
        </div>
        <div class="block has-border">
          <p class="title">分数分布</p>
          <div style="height: 370px" ref="scoreChart"></div>
        </div>
        <div class="block has-border">
          <p class="title">答题情况</p>
          <template v-for="(item, index) in questionList">
            <div :key="index" class="question-type-item">
              <div class="header">
                {{ item.name }}
                <span>{{ item.questionVOS.length }}</span>
                题，共
                <span>{{ totalScore(item.questionVOS) }}</span>
                分
              </div>
              <template v-for="(questionVO, qIndex) in item.questionVOS">
                <QuestionCard
                  class="card"
                  :key="qIndex"
                  :question="{
                    ...questionVO.question,
                    index: qIndex,
                    myAnswer: questionVO.myAnswer,
                    isCorrect: questionVO.myPoint === questionVO.points
                  }"
                  :options="questionOptions"
                ></QuestionCard>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@assets/icon/recruitment/iconfont'
import YTIcon from '@components/common/YTIcon'
import QuestionCard from '@components/common/version2/QuestionCard'
import examApi from '@api/exam'
import { scoreChartOption } from '@components/manage/recruitment/charts/scoreChart'
export default {
  name: 'RecruitmentExamResult',
  components: { YTIcon, QuestionCard },
  data() {
    return {
      fromRoute: '',
      questionList: [],
      examInfoList: [],
      jobResumeId: '',
      scoreChart: null,
      scoreData: {
        userPoints: 0,
        abilityPoints: 0
      },
      questionOptions: {
        showSize: 0,
        myAnswer: {
          show: true
        },
        ability: {
          show: true
        },
        knowledge: {
          show: false
        },
        tag: {
          show: false
        },
        codeTemplate: {
          show: false
        }
      }
    }
  },
  computed: {
    totalScore() {
      return questionList => {
        return questionList.reduce((pre, next) => {
          return this.$floatAdd(pre, next.points || 0) // 统计每种题型的总分
        }, 0)
      }
    },
    breadcrumbs() {
      const routeEnum = {
        jobDetail: [
          { label: '职位管理', to: '/manage/recruitment/job' },
          { label: '查看详情', to: '/manage/recruitment/job/detail' }
        ],
        staffApplyList: [{ label: '人才管理', to: '/manage/recruitment/staff' }]
      }
      return routeEnum[this.fromRoute]
    }
  },
  mounted() {
    const params = this.$handleParams('ExamResultData')
    this.jobResumeId = params.id
    this.fromRoute = params.fromRoute
    this.getExamResult()
    this.getExamInfo()
    this.getScoreInfo()
    window.onresize = () => {
      if (this.scoreChart) {
        this.scoreChart.resize()
      }
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
    this.calculateSize(document.body.clientWidth)
  },
  destroyed() {
    this.scoreChart.dispose()
    this.scoreChart = null
  },
  watch: {
    screenWidth(val) {
      this.calculateSize(val)
    }
  },
  methods: {
    calculateSize(width) {
      if (width <= 1366) {
        this.questionOptions.showSize = 7
      } else if (width > 1366 && width <= 1440) {
        this.questionOptions.showSize = 9
      } else if (width > 1440 && width < 1920) {
        this.questionOptions.showSize = 11
      } else {
        this.questionOptions.showSize = 13
      }
    },
    getExamResult() {
      //获取测评结果
      examApi.getExamResult(this.jobResumeId).then(res => {
        this.questionList = res.res
      })
    },
    getExamInfo() {
      //获取考试结果
      examApi.getExamInfo(this.jobResumeId).then(res => {
        this.examInfoList = res.res
      })
    },
    getScoreInfo() {
      //获取分数分布
      examApi.getScoreInfo(this.jobResumeId).then(res => {
        this.scoreChart = this.$echarts.init(this.$refs.scoreChart)
        const scoreOption = this.$deepCopy(scoreChartOption)
        res.res.forEach(item => {
          scoreOption.xAxis.data.push(item.abilityName)
          scoreOption.series[0].data.push(item.userPoints)
          scoreOption.series[1].data.push(item.abilityPoints)
        })
        this.scoreChart.setOption(scoreOption)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.yt-main {
  padding-top: 0;
}
.yt-container {
  height: 100%;
  padding: 10px;
  overflow-y: auto;
  .yt-content {
    height: auto;
    padding: 0;
    border-radius: 6px;
    box-shadow: none;
  }
}
.block {
  padding: 20px;
  position: relative;
  border-bottom: 1px solid #efefef;
  &.has-border {
    &:before {
      content: '';
      width: 4px;
      height: 20px;
      position: absolute;
      left: 0;
      top: 20px;
      background-color: #448bff;
      border-radius: 1px;
    }
  }
  .title {
    font-size: @default;
    line-height: 20px;
    color: #1f1f1f;
    font-weight: bold;
  }
}
.info {
  display: flex;
  p {
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    color: #1f1f1f;
  }
  svg {
    font-size: 20px;
    margin-right: 6px;
  }
  span {
    font-size: @medium;
    margin-right: 40px;
  }
}
.question-type-item {
  border-bottom: 1px solid #efefef;
  .header {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    margin: 20px 0 16px;
    padding: 0 20px;
    background: #f1f2f6;
    border-radius: 2px;
    color: #6a696e;
    font-size: @medium;
    span {
      margin: 0 6px;
      font-size: @default;
      color: #2878ff;
    }
  }
  .card {
    margin-bottom: 20px;
  }
}
</style>
