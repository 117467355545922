export let scoreChartOption = {
  grid: {
    top: 40,
    left: 40,
    right: 22,
    bottom: 70
  },
  tooltip: {
    show: true
  },
  legend: {
    show: true,
    right: 22,
    top: 10,
    itemWidth: 8,
    itemHeight: 8,
    icon: 'circle',
    textStyle: {
      fontSize: 14
    }
  },
  xAxis: {
    data: [],
    axisLabel: {
      show: true,
      interval: 0
      // width: 150,
      // overflow: 'truncate'
    }
  },
  dataZoom: [
    {
      type: 'inside',
      endValue: 9,
      minValueSpan: 4,
      maxValueSpan: 15
    },
    {
      type: 'slider',
      showDetail: false
    }
  ],
  yAxis: {},
  series: [
    {
      type: 'bar',
      name: '实际分值',
      data: []
    },
    {
      type: 'bar',
      name: '主技能分值',
      data: []
    }
  ]
}
